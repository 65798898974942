import React from 'react'
import { IconPOA } from '../IconPOA'
import { IconTelegram } from '../IconTelegram'
import { IconTwitter } from '../IconTwitter'

const getIconBackgroundColor = networkBranch => {
  return (
    {
      dai: '#e3e7e9',
      poa: '#fff',
      sokol: '#fff',
      kovan: '#fff'
    }[networkBranch] || '#fff'
  )
}

const getIconColor = networkBranch => {
  return (
    {
      dai: '#333',
      poa: '#5c34a2',
      sokol: '#6ac9b9',
      kovan: '#6ac9b9',
      gsys: '#424A92'
    }[networkBranch] || '#000000'
  )
}

export const SocialIcons = ({ extraClass = '', networkBranch = '' }) => {
  const backgroundColor = getIconBackgroundColor(networkBranch)
  const iconColor = getIconColor(networkBranch)

  return (
    <div className={`ft-SocialIcons ${extraClass}`}>
      <IconTwitter
        backgroundColor={backgroundColor}
        color={iconColor}
        text="GSYS Twitter"
        url="https://twitter.com/GenesysChain"
      />
      <IconTelegram
        backgroundColor={backgroundColor}
        color={iconColor}
        text="GSYS Telegram"
        url="https://t.me/GenesysNetwork"
      />
      <IconPOA text="GSYS Network" url="https://kxcoscan.io/" backgroundColor={backgroundColor} color={iconColor} />
    </div>
  )
}
